import React from 'react';
import { defaultIconPropTypes, iconPropTypes } from './proptypes';

const IconLinkedin = ({ height, width, color }) => (
    <svg height={height} width={width} fill={color} xmlns="http://www.w3.org/2000/svg" role="presentation" viewBox="0 0 21 19">
        {/* eslint-disable max-len */}
        <path fillRule="evenodd" d="M11.844 7.187c.096-.058.2-.116.31-.173a6.008 6.008 0 0 1 2.817-.709c3.09 0 5.056 2.32 5.056 6.187v6.05a.455.455 0 0 1-.455.454h-4.546a.455.455 0 0 1-.454-.455v-6.364a1.364 1.364 0 0 0-2.728 0v6.364a.455.455 0 0 1-.454.455H6.844a.455.455 0 0 1-.455-.457 1369.35 1369.35 0 0 0 .012-2.862L6.41 12.6c.007-3.319.001-5.484-.02-5.851a.455.455 0 0 1 .454-.482h4.546c.25 0 .454.204.454.455v.465zm-.909 4.99a2.273 2.273 0 0 1 4.546 0v5.91h3.637v-5.595c0-3.396-1.595-5.277-4.147-5.277-.872 0-1.676.226-2.394.603a5.11 5.11 0 0 0-.663.414c-.11.082-.182.143-.214.172a.455.455 0 0 1-.765-.332v-.896H7.31c.013.876.015 2.767.01 5.426a2105.891 2105.891 0 0 1-.02 5.485h3.635v-5.91zm-8.192-6.82h-.026C1.15 5.358 0 4.143 0 2.648 0 1.145 1.164 0 2.77 0c1.59 0 2.71 1.152 2.744 2.68 0 1.499-1.157 2.678-2.77 2.678zm0-.908c1.108 0 1.862-.768 1.862-1.76C4.582 1.657 3.855.91 2.77.91 1.662.91.91 1.65.91 2.648c0 1.003.754 1.8 1.807 1.8h.026zM5.48 18.54a.455.455 0 0 1-.455.455H.48a.455.455 0 0 1-.454-.455V6.721c0-.25.203-.454.454-.454h4.546c.251 0 .455.204.455.455v11.82zM4.57 7.176H.935v10.91H4.57V7.177z" />
        {/* eslint-enable */}
    </svg>
);

IconLinkedin.propTypes = iconPropTypes;
IconLinkedin.defaultProps = defaultIconPropTypes;

export default IconLinkedin;
