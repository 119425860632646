import React from 'react';
import { defaultIconPropTypes, iconPropTypes } from './proptypes';

const IconMail = ({ height, width, color }) => (
    <svg height={height} width={width} fill={color} xmlns="http://www.w3.org/2000/svg" role="presentation" viewBox="0 0 22 15">
        {/* eslint-disable max-len */}
        <path fillRule="evenodd" d="M1.052 1.682A1 1 0 0 0 1 2v11a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V2a1 1 0 0 0-.052-.318L11.312 9.39a.5.5 0 0 1-.624 0L1.052 1.682zm.768-.666L11 8.36l9.18-7.344A1.006 1.006 0 0 0 20 1H2c-.061 0-.121.006-.18.016zM22 13a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h18a2 2 0 0 1 2 2v11z" />
        {/* eslint-enable */}
    </svg>
);

IconMail.propTypes = iconPropTypes;
IconMail.defaultProps = defaultIconPropTypes;

export default IconMail;
