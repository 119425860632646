import React from 'react';
import { defaultIconPropTypes, iconPropTypes } from './proptypes';

const IconGlobal = ({ height, width, color }) => (
    <svg height={height} width={width} fill={color} xmlns="http://www.w3.org/2000/svg" role="presentation" viewBox="0 0 22 22">
        {/* eslint-disable max-len */}
        <g fillRule="evenodd">
            <path d="M10.45 21.08a10.09 10.09 0 1 1 10.63-10.51.46.46 0 1 0 .92-.04A11.01 11.01 0 1 0 10.4 22a.46.46 0 0 0 .05-.92z" />
            <path d="M15.72 11h-9c.06 1.5.38 3.04.93 4.59h3.36a.46.46 0 0 1 0 .91H8c.67 1.6 1.6 3.19 2.78 4.77a.46.46 0 0 1-.73.55 23.3 23.3 0 0 1-3.04-5.32H1.73a.46.46 0 0 1 0-.91h4.95a16.35 16.35 0 0 1-.88-4.6H.46a.46.46 0 0 1 0-.91h5.33c.04-1.58.32-3.11.87-4.6H2.29v-.91h4.74C7.73 3.02 8.71 1.53 10 .15a.46.46 0 1 1 .67.62 15.66 15.66 0 0 0-2.62 3.8h5.9a15.67 15.67 0 0 0-2.61-3.8.46.46 0 0 1 .67-.62 16.41 16.41 0 0 1 2.96 4.42h4.74v.92h-4.37c.54 1.47.83 3 .87 4.59h5.34a.46.46 0 0 1 0 .92h-5.78a.47.47 0 0 1-.04 0zm-.44-.92a13.21 13.21 0 0 0-.93-4.6h-6.7c-.6 1.48-.9 3.02-.94 4.6h8.57z" />
            <path d="M17.9 18.68a2.99 2.99 0 0 1-1.8 0v.6c0 .2-.14.37-.34.43l-2.3.66c-.28.08-.55.43-.55.72h8.18c0-.3-.27-.64-.56-.72l-2.3-.66a.45.45 0 0 1-.32-.44v-.6zm.92-.47v.72l1.96.56c.68.2 1.22.9 1.22 1.6v.46c0 .25-.2.45-.45.45h-9.1a.45.45 0 0 1-.45-.45v-.46c0-.7.54-1.4 1.22-1.6l1.96-.56v-.72a3.48 3.48 0 0 1-1.36-2.8c0-1.88 1.41-3.41 3.18-3.41 1.77 0 3.18 1.53 3.18 3.4 0 1.16-.54 2.19-1.36 2.8zm.45-2.6c-.64.14-1.4-.05-1.9-.54a3.2 3.2 0 0 1-2.64.44c.05 1.34 1.05 2.4 2.27 2.4 1.19 0 2.17-1 2.27-2.3zm-4.42-1.02c.67.26 1.67.1 2.27-.49a.45.45 0 0 1 .72.11c.25.47.94.63 1.34.49A2.32 2.32 0 0 0 17 12.9c-.99 0-1.84.7-2.15 1.69z" />
        </g>
        {/* eslint-enable */}
    </svg>
);

IconGlobal.propTypes = iconPropTypes;
IconGlobal.defaultProps = defaultIconPropTypes;

export default IconGlobal;
