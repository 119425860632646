import React from 'react';
import { defaultIconPropTypes, iconPropTypes } from './proptypes';

const IconFacebook = ({ height, width, color }) => (
    <svg height={height} width={width} fill={color} xmlns="http://www.w3.org/2000/svg" role="presentation" viewBox="0 0 12 20">
        {/* eslint-disable max-len */}
        <path fillRule="evenodd" d="M7.498 5.802v-1.17c0-.38.15-.504.426-.504h2.49c.23 0 .416-.187.416-.417V.427a.417.417 0 0 0-.415-.416L6.808 0C3.812 0 2.5 1.88 2.5 4.432v1.37H.417A.417.417 0 0 0 0 6.22v3.332c0 .23.186.417.417.417h2.082v9.58c0 .23.187.417.417.417H7.08c.23 0 .417-.186.417-.417v-9.58h2.791a.417.417 0 0 0 .415-.373l.347-3.333a.417.417 0 0 0-.414-.46h-3.14zm2.677.834l-.26 2.499H7.08a.417.417 0 0 0-.416.416v9.58H3.332v-9.58a.417.417 0 0 0-.416-.416H.833v-2.5h2.083c.23 0 .416-.186.416-.416V4.432C3.332 2.29 4.35.833 6.807.833l3.19.01v2.452H7.924c-.703 0-1.26.455-1.26 1.337v1.587c0 .23.187.417.417.417h3.094z" />
        {/* eslint-enable */}
    </svg>
);

IconFacebook.propTypes = iconPropTypes;
IconFacebook.defaultProps = defaultIconPropTypes;

export default IconFacebook;
