import React from 'react';
import { defaultIconPropTypes, iconPropTypes } from './proptypes';

const IconChart = ({ height, width, color }) => (
    <svg height={height} width={width} fill={color} xmlns="http://www.w3.org/2000/svg" role="presentation" viewBox="0 0 22 15">
        {/* eslint-disable max-len */}
        <path fillRule="evenodd" d="M21.083 13.75h.459c.253 0 .458.224.458.5s-.205.5-.458.5H.458c-.253 0-.458-.224-.458-.5s.205-.5.458-.5h.459V9.625c0-.253.205-.458.458-.458h2.75c.253 0 .458.205.458.458v4.125h1.834V5.042c0-.253.205-.459.458-.459h2.75c.253 0 .458.206.458.459v8.708h1.834V6.875c0-.253.205-.458.458-.458h2.75c.253 0 .458.205.458.458v6.875h1.834V.458c0-.253.205-.458.458-.458h2.75c.253 0 .458.205.458.458V13.75zm-.916 0V.917h-1.834V13.75h1.834zm-7.334 0h1.834V7.333h-1.834v6.417zm-3.666 0V5.5H7.333v8.25h1.834zm-7.334 0h1.834v-3.667H1.833v3.667z" />
        {/* eslint-enable */}
    </svg>
);

IconChart.propTypes = iconPropTypes;
IconChart.defaultProps = defaultIconPropTypes;

export default IconChart;
