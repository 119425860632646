import React from 'react';
import { defaultIconPropTypes, iconPropTypes } from './proptypes';

const IconPhone = ({ height, width, color }) => (
    <svg height={height} width={width} fill={color} xmlns="http://www.w3.org/2000/svg" role="presentation" viewBox="0 0 22 22">
        {/* eslint-disable max-len */}
        <path fillRule="evenodd" d="M16.336 17.28c-.89.767-2.149.914-3.088.32a28.531 28.531 0 0 1-8.849-8.847c-.63-1.002-.425-2.354.472-3.25l.865-.864c.752-.755 1.938-.817 2.647-.107l1.421 1.42c.71.711.648 1.895-.106 2.648l-.232.233a28.26 28.26 0 0 0 3.702 3.701l.233-.233c.752-.752 1.935-.814 2.647-.104l1.419 1.42c.711.709.65 1.894-.104 2.647l-.295.295c.295.688.31 1.887-.132 2.829C16.16 21.034 14.224 22 11 22 4.924 22 0 17.076 0 11 0 4.925 4.925 0 11 0s11 4.925 11 11c0 2.251-.678 4.402-1.925 6.218a.458.458 0 0 1-.755-.52A10.028 10.028 0 0 0 21.083 11C21.083 5.431 16.57.917 11 .917 5.43.917.917 5.43.917 11 .917 16.57 5.43 21.083 11 21.083c2.898 0 4.501-.799 5.106-2.085.264-.561.3-1.259.23-1.718zM8.525 8.477l.525-.525c.41-.41.442-1.015.106-1.352L7.735 5.18c-.336-.336-.942-.304-1.35.107l-.867.864c-.603.603-.737 1.487-.343 2.113a27.615 27.615 0 0 0 8.562 8.561c.623.394 1.51.26 2.112-.342l.866-.867c.41-.41.44-1.015.104-1.351l-1.418-1.42c-.338-.336-.943-.304-1.352.105l-.526.525a.458.458 0 0 1-.61.033 29.03 29.03 0 0 1-4.422-4.42.458.458 0 0 1 .034-.61z" />
        {/* eslint-enable */}
    </svg>
);

IconPhone.propTypes = iconPropTypes;
IconPhone.defaultProps = defaultIconPropTypes;

export default IconPhone;
