import React from 'react';
import { defaultIconPropTypes, iconPropTypes } from './proptypes';

const IconPinterest = ({ height, width, color }) => (
    <svg height={height} width={width} fill={color} xmlns="http://www.w3.org/2000/svg" role="presentation" viewBox="0 0 20 20">
        {/* eslint-disable max-len */}
        <path fillRule="evenodd" d="M.154 6.587c-.462 2.505.136 4.968 1.517 6.397a.417.417 0 0 0 .437.104c.235-.082.468-.19.7-.32.518-.292.869-.585 1.598-1.274.314-.297.454-.426.63-.577a.417.417 0 0 0 .044-.589c-1.964-2.276-.156-5.491 3.088-6.275 3.048-.734 6.574.689 7.33 3.257.754 2.562-1.462 4.544-4.308 3.872a8.966 8.966 0 0 0-.21-.046c-.194-.042-.147-.005-.147-.06V6.108c0-.454-.137-.677-.555-.704a1.81 1.81 0 0 1-.12-.01 18.17 18.17 0 0 0-.682-.068C8.4 5.242 7.706 5.24 7 5.36l-.145.022c-.396.063-.51.277-.51.706a506.69 506.69 0 0 0 .032 4.805c.007.88.031 3.362.031 3.366v.018c-.02.515-.028.65-.066.917-.07.486-.202.785-.34.846-.233.103-.437.053-.965-.209l-.208-.102a2.576 2.576 0 0 0-.575-.207.417.417 0 0 0-.501.448c.018.185.01.367-.034.896-.016.199-.024.314-.031.44-.067 1.181.128 1.901.906 2.238 1.161.504 2.39.616 3.475.247 2.017-.684 3.065-2.762 2.955-5.025 3.37.71 6.914-1.009 8.378-4.102 1.086-2.293.662-5.168-1.012-7.164C15.523.08 9.57-.921 5.02.883 2.386 1.923.642 3.943.154 6.587zm5.02-5.317l.153.387c4.239-1.68 9.807-.742 12.423 2.38 1.469 1.75 1.842 4.278.898 6.272-1.374 2.902-4.832 4.448-7.975 3.51a.417.417 0 0 0-.532.456c.296 2.132-.578 4.13-2.34 4.728-.875.297-1.893.204-2.877-.222-.34-.148-.454-.568-.405-1.426.006-.119.015-.228.03-.42.013-.158.023-.288.03-.399l.087.043c.742.368 1.132.463 1.672.224.49-.217.72-.74.828-1.49.043-.307.052-.456.073-1.003v-.016c.002-.028-.025-2.837-.03-3.408l-.01-1.115c-.013-1.595-.02-2.82-.021-3.595.61-.1 1.242-.097 2.232-.02.205.017.4.036.59.059v4.861c0 .544.273.76.805.875.1.02.147.03.194.042 3.433.81 6.244-1.704 5.299-4.918-.899-3.051-4.886-4.66-8.325-3.833-3.637.88-5.799 4.515-3.779 7.308-.096.088-.207.193-.356.334-.685.648-1 .91-1.44 1.158a3.84 3.84 0 0 1-.312.158C1.036 10.948.58 8.872.974 6.738c.434-2.353 1.98-4.142 4.353-5.08l-.154-.388z" />
        {/* eslint-enable */}
    </svg>
);

IconPinterest.propTypes = iconPropTypes;
IconPinterest.defaultProps = defaultIconPropTypes;

export default IconPinterest;
