import React from 'react';
import { defaultIconPropTypes, iconPropTypes } from './proptypes';

const IconSpinner = ({ height, width, color }) => (
    <svg
        className="spinner"
        height={height}
        width={width}
        fill={color}
        stroke="#fff"
        xmlns="http://www.w3.org/2000/svg"
        role="presentation"
        viewBox="0 0 66 66"
    >
        <circle className="circle" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30" />
    </svg>
);

IconSpinner.propTypes = iconPropTypes;
IconSpinner.defaultProps = defaultIconPropTypes;

export default IconSpinner;
