import React from 'react';
import { defaultIconPropTypes, iconPropTypes } from './proptypes';

const IconCheckmark = ({ height, width, color }) => (
    <svg height={height} width={width} fill={color} xmlns="http://www.w3.org/2000/svg" role="presentation" viewBox="0 0 18 16">
        {/* eslint-disable max-len */}
        <path fillRule="evenodd" d="M17.886 2.349L15.661.117a.399.399 0 0 0-.565 0l-9.4 9.406a.399.399 0 0 1-.564 0L2.907 7.291a.399.399 0 0 0-.565 0L.117 9.523a.399.399 0 0 0 0 .564l5.015 5.016a.399.399 0 0 0 .565 0l12.19-12.196a.399.399 0 0 0 0-.558z" />
        {/* eslint-enable */}
    </svg>
);

IconCheckmark.propTypes = iconPropTypes;
IconCheckmark.defaultProps = defaultIconPropTypes;

export default IconCheckmark;
