const HostsToDecorate = ['mdrt.org', 'mdrtest.org'];
const UtmKeys = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_term',
    'utm_content',
];

function ParseUrl(url) {
    try {
        return new URL(url);
    } catch {
        return null;
    }
}

function ApplyUtmParametersToUrl(url) {
    const urlObj = ParseUrl(url);
    if (!urlObj || !urlObj.host || !HostsToDecorate.some(x => urlObj.host.includes(x)) || !window || !window.location) {
        return url;
    }

    const urlParams = new URLSearchParams(window.location.search);
    const paramsToApply = UtmKeys
        .map(paramKey => ({ key: paramKey, value: urlParams.get(paramKey) }))
        .filter(x => !!x.value);

    paramsToApply.forEach(({ key, value }) => {
        urlObj.searchParams.delete(key);
        urlObj.searchParams.append(key, value);
    });
    return urlObj.href;
}

export default ApplyUtmParametersToUrl;
