import React from 'react';
import { defaultIconPropTypes, iconPropTypes } from './proptypes';

const IconChat = ({ height, width, color }) => (
    <svg height={height} width={width} fill={color} xmlns="http://www.w3.org/2000/svg" role="presentation" viewBox="0 0 22 21">
        {/* eslint-disable max-len */}
        <path fillRule="evenodd" d="M4.58 12.34h-.46A4.04 4.04 0 0 1 0 8.38V4.87A4.04 4.04 0 0 1 4.13.92h9.16a4.04 4.04 0 0 1 4.13 3.95v3.51a4.04 4.04 0 0 1-4.13 3.96H9.34l-4 3.4c-.29.25-.76.05-.76-.33v-3.07zm.92 2.1l3.36-2.87c.09-.07.2-.11.3-.11h4.13c1.77 0 3.21-1.38 3.21-3.08V4.87c0-1.7-1.44-3.07-3.2-3.07H4.11a3.14 3.14 0 0 0-3.2 3.07v3.51c0 1.7 1.43 3.08 3.2 3.08h.92c.25 0 .46.2.46.44v2.53zm12.05 5.67l-2.61-2.5h-2.1a2.7 2.7 0 0 1-2.76-2.64c0-.24.2-.44.46-.44.25 0 .46.2.46.44 0 .97.82 1.76 1.83 1.76h2.3c.12 0 .23.05.32.13l1.97 1.88v-1.57c0-.24.2-.44.45-.44h1.38a1.8 1.8 0 0 0 1.83-1.76v-1.75a1.8 1.8 0 0 0-1.83-1.76.45.45 0 0 1-.46-.44c0-.24.2-.44.46-.44A2.7 2.7 0 0 1 22 13.22v1.75a2.7 2.7 0 0 1-2.75 2.64h-.92v2.2c0 .39-.49.58-.78.3z" />
        {/* eslint-enable */}
    </svg>
);

IconChat.propTypes = iconPropTypes;
IconChat.defaultProps = defaultIconPropTypes;

export default IconChat;
