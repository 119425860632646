import React from 'react';
import { defaultIconPropTypes, iconPropTypes } from './proptypes';

const IconEducation = ({ height, width, color }) => (
    <svg height={height} width={width} fill={color} xmlns="http://www.w3.org/2000/svg" role="presentation" viewBox="0 0 22 22">
        {/* eslint-disable max-len */}
        <path fillRule="evenodd" d="M11.5 18.41v.24h-.96c0-1.64-.76-2.39-2.41-2.39H2.39a.48.48 0 0 1-.48-.48V2.87H.96v14.35h5.26c2.1 0 3.58 1.05 3.8 2.87h1.96c.22-1.82 1.7-2.87 3.8-2.87h5.26V2.87h-.95v12.91c0 .27-.22.48-.48.48h-5.74c-1.34 0-2.08.5-2.3 1.57v.34c0 .1-.03.17-.07.24zm-.98-2.35V3.36v-.01c0-1.65-.74-2.4-2.39-2.4H2.87v1.42a.49.49 0 0 1 0 .04v12.9h5.26a3.5 3.5 0 0 1 2.4.75zm1.05-.06c.55-.46 1.32-.7 2.3-.7h5.26V.96h-5.26c-1.65 0-2.3.74-2.3 2.39V16zm-1.02 2.32l-.01.33c0 .64.95.64.95 0v-.22a.54.54 0 0 1-.44.22.52.52 0 0 1-.5-.33zM1.9 1.92V.47c0-.27.22-.48.48-.48h5.74C9.5 0 10.47.46 11 1.34 11.53.46 12.5 0 13.87 0h5.74c.26 0 .48.21.48.48V1.9h1.43c.27 0 .48.22.48.48v15.3c0 .27-.21.48-.48.48h-5.74c-1.79 0-2.87.87-2.87 2.4 0 .26-.21.47-.48.47H9.57a.48.48 0 0 1-.48-.47c0-1.53-1.08-2.4-2.87-2.4H.48A.48.48 0 0 1 0 17.7V2.4c0-.27.21-.49.48-.49H1.9z" />
        {/* eslint-enable */}
    </svg>
);

IconEducation.propTypes = iconPropTypes;
IconEducation.defaultProps = defaultIconPropTypes;

export default IconEducation;
