import React from 'react';
import { defaultIconPropTypes, iconPropTypes } from './proptypes';

const IconTrophy = ({ height, width, color }) => (
    <svg height={height} width={width} fill={color} xmlns="http://www.w3.org/2000/svg" role="presentation" viewBox="0 0 22 22">
        {/* eslint-disable max-len */}
        <path fillRule="evenodd" d="M2.363 2.75H.918c-.01 1.174.043 2.05.246 2.998.394 1.843 1.23 3.01 2.67 3.33-.807-1.557-1.3-3.595-1.47-6.328zm2.655 6.545a.459.459 0 0 1 .109.164c.444.647.963 1.18 1.555 1.63.441.336.88.609 1.74 1.12 1.482.919 2.09 1.75 2.09 3.383-.055 3.076-1.384 4.431-4.091 4.564v.927h9.164v-.927c-2.755-.133-4.156-1.512-4.156-4.573 0-1.628.615-2.464 2.11-3.391.138-.086.718-.435.868-.528.343-.212.62-.399.885-.6a7.105 7.105 0 0 0 1.564-1.625.46.46 0 0 1 .091-.134c1.022-1.543 1.629-3.71 1.797-6.9a.46.46 0 0 1 .013-.27c.018-.39.029-.796.033-1.218H3.216c.042 4.012.655 6.607 1.802 8.378zm-.577.783C2.114 9.97.796 8.412.268 5.939.039 4.87-.013 3.906.003 2.594l.003-.302c0-.253.205-.459.458-.459h1.855c-.015-.441-.022-.899-.022-1.375 0-.253.205-.458.458-.458H19.25c.253 0 .458.205.458.458 0 .476-.008.934-.023 1.375h1.851c.253 0 .459.206.459.459l.002.279c.014 1.318-.04 2.289-.276 3.37-.538 2.474-1.864 4.033-4.196 4.137a7.994 7.994 0 0 1-1.68 1.717c-.29.22-.591.422-.956.648-.158.098-.737.447-.868.528-1.252.776-1.676 1.354-1.676 2.612 0 2.69 1.12 3.667 3.697 3.667.253 0 .459.205.459.458v1.834a.458.458 0 0 1-.459.458H5.963a.458.458 0 0 1-.459-.458v-1.834c0-.253.205-.458.458-.458 2.533 0 3.585-.948 3.634-3.667 0-1.256-.419-1.827-1.658-2.595-.846-.503-1.33-.803-1.81-1.169a7.899 7.899 0 0 1-1.687-1.741zm15.2-7.328c-.173 2.733-.675 4.77-1.496 6.326 1.44-.321 2.281-1.488 2.682-3.33.206-.951.263-1.827.256-2.996h-1.442zm-9.08.253a.458.458 0 0 1 .82 0l.79 1.58h1.55c.437 0 .626.557.277.823l-1.237.945.485 1.779a.458.458 0 0 1-.717.487l-1.558-1.17-1.558 1.17a.458.458 0 0 1-.708-.519l.613-1.745-1.356-.934a.458.458 0 0 1 .26-.836h1.55l.789-1.58zm-.097 2.244a.458.458 0 0 1-.41.253h-.358l.43.296c.17.117.241.334.173.53l-.23.652.627-.47a.458.458 0 0 1 .55 0l.765.574-.215-.788a.458.458 0 0 1 .164-.484l.405-.31h-.478a.458.458 0 0 1-.41-.253l-.506-1.014-.507 1.014z" />
        {/* eslint-enable */}
    </svg>
);

IconTrophy.propTypes = iconPropTypes;
IconTrophy.defaultProps = defaultIconPropTypes;

export default IconTrophy;
