import React from 'react';
import { defaultIconPropTypes, iconPropTypes } from './proptypes';

const IconMoney = ({ height, width, color }) => (
    <svg height={height} width={width} fill={color} xmlns="http://www.w3.org/2000/svg" role="presentation" viewBox="0 0 22 21">
        {/* eslint-disable max-len */}
        <path fillRule="evenodd" d="M4.583 12.342h2.292c1.777 0 3.198.74 4.312 1.763h2.563c.917 0 1.428.518 1.534 1.096l3.382-1.079c1.195-.328 2.13-.035 3.2.994a.432.432 0 0 1-.12.706 170.282 170.282 0 0 0-3.502 1.735 560.45 560.45 0 0 1-2.5 1.272c-1.595.794-2.472 1.14-3.353 1.276-.929.144-1.812.037-3.162-.35-.632-.181-3.341-1.08-4.646-1.508v.744a.45.45 0 0 1-.458.44H.458a.45.45 0 0 1-.458-.44v-7.09a.45.45 0 0 1 .458-.44h3.667a.45.45 0 0 1 .458.44v.441zm0 .882v4.091c1.143.374 4.244 1.405 4.908 1.595 1.23.353 1.984.444 2.754.325.757-.117 1.564-.435 3.077-1.189a562.77 562.77 0 0 0 2.494-1.268c1.02-.519 1.942-.98 2.92-1.454-.615-.458-1.133-.542-1.8-.36l-3.905 1.246c-.252.317-.68.54-1.281.54H8.708a.45.45 0 0 1-.458-.44.45.45 0 0 1 .458-.442h5.042c.85 0 .85-.881 0-.881H11a.468.468 0 0 1-.324-.13c-.979-.94-2.244-1.633-3.801-1.633H4.583zm-.916 4.397v-5.279H.917v6.208h2.75v-.908a.42.42 0 0 1 0-.02zm9.166-14.535C12.833 1.38 14.27 0 16.042 0s3.208 1.381 3.208 3.086c0 1.704-1.436 3.085-3.208 3.085-1.772 0-3.209-1.381-3.209-3.085zm.917 0c0 1.217 1.026 2.203 2.292 2.203 1.265 0 2.291-.986 2.291-2.203 0-1.218-1.026-2.204-2.291-2.204-1.266 0-2.292.986-2.292 2.204zm-5.5 6.17c0-1.704 1.436-3.085 3.208-3.085 1.772 0 3.209 1.381 3.209 3.086 0 1.704-1.437 3.085-3.209 3.085S8.25 10.961 8.25 9.257zm.917 0c0 1.218 1.026 2.205 2.291 2.205 1.266 0 2.292-.987 2.292-2.204 0-1.218-1.026-2.204-2.292-2.204-1.265 0-2.291.986-2.291 2.204zM11 8.376c0-.243.224-.44.5-.44s.5.197.5.44v1.763c0 .244-.224.44-.5.44s-.5-.196-.5-.44V8.375zm4.583-6.171c0-.243.224-.44.5-.44s.5.197.5.44v1.763c0 .244-.224.44-.5.44s-.5-.196-.5-.44V2.204z" />
        {/* eslint-enable */}
    </svg>
);

IconMoney.propTypes = iconPropTypes;
IconMoney.defaultProps = defaultIconPropTypes;

export default IconMoney;
