import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import ButtonLoader from 'components/ButtonLoader';
import ApplyUtmParametersToUrl from 'utilities/applyUtmParams';
import isBrowser from 'utilities/isBrowser';
import { ButtonVariants } from './constants';

const Button = ({
    children,
    className,
    displayBlockOnMobile,
    href,
    variant,
    loading,
    ...other
}) => {
    const [stateHref, setHref] = useState(href);
    useEffect(() => {
        if (href) {
            const newHref = isBrowser ? ApplyUtmParametersToUrl(href) : href;
            setHref(newHref);
        }
    }, [setHref, href]);

    const buttonClass = classNames(
        'button',
        {
            [`button--${variant}`]: true,
            'button--full-width-on-mobile': displayBlockOnMobile,
        },
        className
    );
    let Element = 'button';
    const attributes = {
        className: buttonClass,
        variant,
        ...other,
    };

    if (href) {
        Element = 'a';
        attributes.href = stateHref;
    }

    return (
        <Element {...attributes}>
            {loading ? (
                <>
                    <span className="visually-hidden">
                        <FormattedMessage id="LOADING" />
                    </span>
                    <ButtonLoader />
                </>
            ) : (
                children
            )}
        </Element>
    );
};

Button.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    displayBlockOnMobile: PropTypes.bool,
    href: PropTypes.string,
    variant: PropTypes.oneOf(Object.values(ButtonVariants)),
    loading: PropTypes.bool,
};

Button.defaultProps = {
    className: '',
    displayBlockOnMobile: false,
    href: '',
    variant: '',
    loading: false,
};

export default Button;
