import React from 'react';
import { defaultIconPropTypes, iconPropTypes } from './proptypes';

const IconAddress = ({ height, width, color }) => (
    <svg height={height} width={width} fill={color} xmlns="http://www.w3.org/2000/svg" role="presentation" viewBox="0 0 16 20">
        {/* eslint-disable max-len */}
        <path fillRule="evenodd" d="M15.833 8.333c0 2.678-1.45 5.418-3.857 8.092a27.707 27.707 0 0 1-3.481 3.243c-.158.123-.272.208-.334.253a.417.417 0 0 1-.488 0 25.193 25.193 0 0 1-1.198-.958 27.707 27.707 0 0 1-2.618-2.538C1.451 13.75 0 11.01 0 8.333 0 3.653 3.386 0 7.917 0c4.53 0 7.916 3.653 7.916 8.333zM7.983 19.01a26.883 26.883 0 0 0 3.374-3.143C13.638 13.332 15 10.76 15 8.333c0-4.234-3.029-7.5-7.083-7.5C3.86.833.833 4.1.833 8.333c0 2.427 1.362 5 3.643 7.534a26.883 26.883 0 0 0 3.44 3.195l.067-.052zM12.5 7.917a4.584 4.584 0 1 1-9.168-.001 4.584 4.584 0 0 1 9.168 0zm-.833 0a3.75 3.75 0 1 0-7.501 0 3.75 3.75 0 0 0 7.5 0z" />
        {/* eslint-enable */}
    </svg>
);

IconAddress.propTypes = iconPropTypes;
IconAddress.defaultProps = defaultIconPropTypes;

export default IconAddress;
