import React from 'react';
import { defaultIconPropTypes, iconPropTypes } from './proptypes';

const IconTwitter = ({ height, width, color }) => (
    <svg height={height} width={width} fill={color} xmlns="http://www.w3.org/2000/svg" role="presentation" viewBox="0 0 21 17">
        {/* eslint-disable max-len */}
        <path fillRule="evenodd" d="M19.22 1.442c.04-.117.19-.595.19-.595a.431.431 0 0 0-.631-.501c-.66.391-1.377.68-2.128.852a4.349 4.349 0 0 0-7.327 3.573A10.686 10.686 0 0 1 2.096.875a.431.431 0 0 0-.707.056C1.005 1.59.8 2.34.8 3.118c0 .907.28 1.767.776 2.48a3.51 3.51 0 0 1-.166-.086.431.431 0 0 0-.64.354v.029l-.001.043A4.35 4.35 0 0 0 2.51 9.42a.432.432 0 0 0-.348.558 4.357 4.357 0 0 0 2.92 2.846 7.456 7.456 0 0 1-4.6.937c-.454-.053-.667.544-.282.79a11.518 11.518 0 0 0 6.238 1.828c6.795 0 11.575-5.423 11.575-11.576 0-.103-.001-.198-.003-.29l.087-.06.39-.267c.837-.575 1.292-.964 1.652-1.504.24-.359-.14-.809-.534-.633-.295.132-.557.2-.807.203.183-.217.305-.464.423-.81zM18 3.475l-.392.268c-.11.076-.202.14-.289.203a.431.431 0 0 0-.178.37c.007.15.01.306.01.487 0 5.7-4.42 10.714-10.713 10.714-1.487 0-2.928-.304-4.256-.882a8.278 8.278 0 0 0 4.315-1.728.431.431 0 0 0-.257-.77 3.493 3.493 0 0 1-2.983-1.79c.404.006.807-.045 1.198-.152.439-.119.418-.749-.028-.838a3.49 3.49 0 0 1-2.739-2.78c.407.139.834.218 1.272.232.433.014.614-.548.253-.789A3.484 3.484 0 0 1 1.87 1.93a11.539 11.539 0 0 0 7.945 3.742.431.431 0 0 0 .442-.528 3.487 3.487 0 0 1 5.94-3.18.431.431 0 0 0 .399.128c.557-.11 1.099-.277 1.618-.497a1.109 1.109 0 0 1-.228.252c-.09.075-.202.154-.346.24a.431.431 0 0 0 .03.756c.285.14.567.226.853.258-.153.115-.325.239-.523.374z" />
        {/* eslint-enable */}
    </svg>
);

IconTwitter.propTypes = iconPropTypes;
IconTwitter.defaultProps = defaultIconPropTypes;

export default IconTwitter;
