import React from 'react';
import { defaultIconPropTypes, iconPropTypes } from './proptypes';

const IconClose = ({ height, width, color }) => (
    <svg height={height} width={width} fill={color} xmlns="http://www.w3.org/2000/svg" role="presentation" viewBox="0 0 20 20">
        {/* eslint-disable max-len */}
        <path fillRule="evenodd" d="M10 9.41L19.29.12a.42.42 0 0 1 .59.6L10.58 10l9.3 9.29a.42.42 0 0 1-.6.59L10 10.58l-9.29 9.3a.42.42 0 0 1-.59-.6L9.42 10 .11.71a.42.42 0 0 1 .6-.59L10 9.42z" />
        {/* eslint-enable */}
    </svg>
);

IconClose.propTypes = iconPropTypes;
IconClose.defaultProps = defaultIconPropTypes;

export default IconClose;
