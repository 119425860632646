import React from 'react';
import { defaultIconPropTypes, iconPropTypes } from './proptypes';

const IconHamburger = ({ height, width, color }) => (
    <svg height={height} width={width} fill={color} xmlns="http://www.w3.org/2000/svg" role="presentation" viewBox="0 0 16 13">
        {/* eslint-disable max-len */}
        <path fillRule="evenodd" d="M.5 0h15a.5.5 0 1 1 0 1H.5a.5.5 0 0 1 0-1zm0 4h15a.5.5 0 1 1 0 1H.5a.5.5 0 1 1 0-1zm0 4h15a.5.5 0 1 1 0 1H.5a.5.5 0 1 1 0-1zm0 4h15a.5.5 0 1 1 0 1H.5a.5.5 0 1 1 0-1z" />
        {/* eslint-enable */}
    </svg>
);

IconHamburger.propTypes = iconPropTypes;
IconHamburger.defaultProps = defaultIconPropTypes;

export default IconHamburger;
