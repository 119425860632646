import PropTypes from 'prop-types';

export const iconPropTypes = {
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export const defaultIconPropTypes = {
    color: '',
    height: null,
    width: null,
};
